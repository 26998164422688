import React from "react";
import * as SC from "./Header.styles";
import mail_ic from "../../assets/images/ic/mail.svg";
import phone_ic from "../../assets/images/ic/phone_call.svg";
import { useSelector } from "react-redux";
import { get } from "lodash";

export default function Header() {
  const { CONTACT, LOGO } = useSelector(
    (state) => state.commonReducer.dataPage
  );
  return CONTACT ? (
    <SC.Header>
      <div className="header-box">
        <div className="header-title">
          <SC.IMGLOGO src={get(LOGO, "LOGO_IMG", "")}></SC.IMGLOGO>
        </div>
        <div className="header-contact">
          <SC.RowCT>
            <img src={phone_ic} alt="Hotline Golden" />
            <SC.COLCT>
              <SC.TXTHOTLINE className="rz_txthotline">HOTLINE</SC.TXTHOTLINE>
              <SC.TITLEHOTLINESUB>
                {CONTACT.CONTACT_HOTLINE || ""}
              </SC.TITLEHOTLINESUB>
            </SC.COLCT>
          </SC.RowCT>
          <SC.RowCT>
            <img src={mail_ic} alt="Hotline Golden" />
            <SC.COLCT>
              <SC.TXTHOTLINE>EMAIL</SC.TXTHOTLINE>
              <SC.TITLEHOTLINESUB>
                {CONTACT.CONTACT_EMAIL || ""}
              </SC.TITLEHOTLINESUB>
            </SC.COLCT>
          </SC.RowCT>
        </div>
      </div>
    </SC.Header>
  ) : null;
}
