import styled from "styled-components";
import { BG_BLUE, COLOR_YELLOW } from "../../UIKIT/Variables";
import { device, inner } from "@iso/UIKIT/device.js";
import { Link } from "react-router-dom";
const NavWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
  .nav-icon,
  .navbar_mobile {
    display: none;
  }
  /* // nav_icon */
  .nav-icon {
    width: 30px;
    margin: 5px;
  }

  .nav-icon:after,
  .nav-icon:before,
  .nav-icon div {
    background-color: #072f4f;
    border-radius: 3px;
    content: "";
    display: block;
    height: 3px;
    margin: 7px 0;
    transition: all 0.2s ease-in-out;
  }
  .nav-icon.active:before {
    transform: translateY(12px) rotate(135deg);
  }

  .nav-icon.active:after {
    transform: translateY(-12px) rotate(-135deg);
  }

  .nav-icon.active div {
    transform: scale(0);
  }
  /* // end-nav_icon */
`;

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.laptopL} {
    width: ${inner};
  }
  .desktop_none {
    display: none;
  }

  @media ${device.tablet} {
    .mobile_none {
      display: none;
    }
    .desktop_none {
      display: block;
      margin: 0 auto;
    }
    img.desktop_none {
      text-align: center;
      width: 190px;
      padding: 10px 0px;
    }
  }
  @media ${device.mobileL} {
    img.desktop_none {
      width: initial;
      max-width: 45%;
    }
  }
  nav {
    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      li {
        padding: 28px;
        @media ${device.laptopL} {
          padding: 18px;
        }
      }
      li.active {
        border-bottom: ${COLOR_YELLOW} solid 4px;
        a {
          color: ${BG_BLUE};
        }
        @media ${device.laptopL} {
          border-bottom: ${COLOR_YELLOW} solid 2px;
        }
      }
    }
  }
`;
const Social = styled.div`
  width: 150px;
  height: 100%;
  display:flex;
  justify-content: flex-end;
  > a {
    display:flex;
    border-radius: 1000px;
    background-color: #072f4f;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
const SocialMB = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const NavLink = styled(Link)`
  font-weight: bold;
  font-size: 18px;
  color: rgba(7, 47, 79, 0.6);
  text-decoration: none;
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;
const Nav = styled.div`
  flex: 1;
  @media ${device.tablet} {
    flex: initial;
    .nav-icon {
      display: block;
    }
    nav {
      display: none;
    }
    .navbar-toggle-overlay.active {
      display: block;
      width: 100%;
      height: 4414px;
      position: fixed;
      left: 0;
      z-index: 500 !important;
    }
    .navbar_mobile {
      display: block;
      z-index: 999;
      position: fixed;
      z-index: 555 !important;
      top: 0;
      left: -90%;
      width: 0;
      height: 100%;
      background-color: #072f4f;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
      transition-duration: 0.2s;
      -webkit-transition-duration: 0.2s;
      transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;
      ul {
        width: 100%;
        padding-left: 15px;
        flex-direction: column !important;
        li {
          width: 100%;
          @media ${device.tablet} {
            padding: 8px 13px 3px 18px;
          }
          a {
            color: #fff;
            margin: 0px;
            font-size: 20px;
            padding: 0px;
          }
        }
        li.active {
          a {
            color: #ff8400;
          }
          border-bottom: initial;
        }
      }
    }
    .navbar_mobile.active {
      left: -15px;
      top: 0;
      z-index: 555 !important;
      width: 250px;
      display: block;
      position: fixed;
      height: 100%;
    }
  }
`;
export { NavWrapper, Content, NavLink, Social, Nav, SocialMB };
