import React from "react";
import { useRouteMatch } from "react-router-dom";
import * as SC from "./Nav.styles";
import { renderWebsite } from "@iso/utils/function.util";
import lg_mb from "@iso/assets/images/lgMobile.svg";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { get } from "lodash";

export default function Nav() {
  const [toggle, setToggle] = React.useState(false);
  const { CONTACT } = useSelector((state) => state.commonReducer.dataPage);
  let nav_icon = classnames("nav-icon", { active: toggle });
  let nav_mobile = classnames("navbar_mobile", { active: toggle });
  let overlay = classnames("navbar-toggle-overlay", {
    active: toggle,
  });
  return (
    <React.Fragment>
      {/* nav_desktop */}
      <SC.NavWrapper>
        <SC.Content>
          <SC.Nav>
            <div
              className={`${nav_icon}`}
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <div></div>
            </div>
            {/* nav_desktop */}
            <nav>
              <ul>
                <OldSchoolMenuLink
                  activeOnlyWhenExact={true}
                  to="/"
                  label="Home"
                />
                <OldSchoolMenuLink to="/service" label="Service" />
                <OldSchoolMenuLink to="/recruitment" label="Recruitment" />
                <OldSchoolMenuLink to="/blog" label="Blog" />
                <OldSchoolMenuLink to="/contact" label="Contact" />
                <OldSchoolMenuLink to="/about" label="About Us" />
              </ul>

              <img src={lg_mb} alt="" className="desktop_none" />
            </nav>
            {/* end_nav_desktop */}
            {/* nav_mobile */}
            <nav className={`${nav_mobile}`}>
              <ul>
                <OldSchoolMenuLink
                  activeOnlyWhenExact={true}
                  to="/"
                  label="Home"
                />
                <OldSchoolMenuLink to="/service" label="Service" />
                <OldSchoolMenuLink to="/recruitment" label="Recruitment" />
                <OldSchoolMenuLink to="/blog" label="Blog" />
                <OldSchoolMenuLink to="/contact" label="Contact" />
                <OldSchoolMenuLink to="/about" label="About Us" />
              </ul>
              {CONTACT && (
                <SC.SocialMB>
                  {CONTACT.CONTACT_LINKEDIN_LINK && (
                    <a
                      href={
                        "http://" + renderWebsite(CONTACT.CONTACT_LINKEDIN_LINK)
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      alt=""
                    >
                      <img
                        src={get(CONTACT, "CONTACT_LINKEDIN_IMG", "")}
                        alt=""
                      ></img>
                    </a>
                  )}
                  {CONTACT.CONTACT_YOUTUBE_LINK && (
                    <a
                      href={
                        "http://" + renderWebsite(CONTACT.CONTACT_YOUTUBE_LINK)
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      alt=""
                    >
                      <img src={get(CONTACT, "CONTACT_YOUTUBE_IMG", "")} alt=""></img>
                    </a>
                  )}
                  {CONTACT.CONTACT_TWITTER_LINK && (
                    <a
                      href={
                        "http://" + renderWebsite(CONTACT.CONTACT_TWITTER_LINK)
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      alt=""
                    >
                      <img
                        src={get(CONTACT, "CONTACT_TWITTER_IMG", "")}
                        alt=""
                      ></img>
                    </a>
                  )}
                  {CONTACT.CONTACT_FACEBOOK_LINK && (
                    <a
                      href={
                        "http://" + renderWebsite(CONTACT.CONTACT_FACEBOOK_LINK)
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      alt=""
                    >
                      <img
                        src={get(CONTACT, "CONTACT_FACEBOOK_IMG", "")}
                        alt=""
                      ></img>
                    </a>
                  )}
                </SC.SocialMB>
              )}
            </nav>
            <div
              className={overlay}
              onClick={() => {
                setToggle(false);
              }}
            />
            {/* end_nav_mobile */}
          </SC.Nav>
          {CONTACT && (
            <SC.Social className="mobile_none">
              {CONTACT.CONTACT_LINKEDIN_LINK && (
                <a
                  href={
                    "http://" + renderWebsite(CONTACT.CONTACT_LINKEDIN_LINK)
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_LINKEDIN_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_YOUTUBE_LINK && (
                <a
                  href={"http://" + renderWebsite(CONTACT.CONTACT_YOUTUBE_LINK)}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_YOUTUBE_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_TWITTER_LINK && (
                <a
                  href={"http://" + renderWebsite(CONTACT.CONTACT_TWITTER_LINK)}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_TWITTER_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_FACEBOOK_LINK && (
                <a
                  href={
                    "http://" + renderWebsite(CONTACT.CONTACT_FACEBOOK_LINK)
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img src={get(CONTACT, "CONTACT_FACEBOOK_IMG", "")} alt=""></img>
                </a>
              )}
            </SC.Social>
          )}
          <img src={lg_mb} alt="" className="desktop_none" />
        </SC.Content>
      </SC.NavWrapper>
    </React.Fragment>
  );
}
function OldSchoolMenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <li className={match ? "active" : ""}>
      <SC.NavLink to={to}>{label}</SC.NavLink>
    </li>
  );
}
