import closeE from "@iso/assets/images/ic/closeE.svg";
import articleAction from "@iso/redux/Article/actions";
import commonAction from "@iso/redux/Common/actions";
// import Slide from "@iso/containers/Slide/Slide";
import { renderWebsite } from "@iso/utils/function.util";
import { BackTop } from "antd";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import { Loader } from "../../components/Loader/Loader";
import { publicRoutes } from "../../router";
import Footer from "./../Footer/Footer";
import Header from "./../Header/Header";
import Nav from "./../Nav/Nav";
import { DashboardContainer, DashboardGlobalStyles } from "./Layout.styles";

const {
  getDataArticleService,
  getDataArticleBlog,
  getDataTopHiring,
} = articleAction;
const {
  getTitleList,
  getKeyRecaptcha,
  getKeyGoogleMap,
  getTagList,
  getSalaryType,
} = commonAction;

export default function Layout() {
  const dispatch = useDispatch();
  const { loadingPage, dataPage } = useSelector((state) => state.commonReducer);
  const [isEvent, setIsEvent] = React.useState(true);
  useEffect(() => {
    dispatch(getTitleList());
    dispatch(getKeyRecaptcha());
    dispatch(getKeyGoogleMap());
    dispatch(getTagList());
    dispatch(getDataArticleService());
    dispatch(getSalaryType());
    dispatch(getDataTopHiring());
    getListBlog();

    //recent fixed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getListBlog() {
    let model = {
      page: 1,
      limit: 4,
    };
    dispatch(getDataArticleBlog(model));
  }
  function renderColor(color) {
    try {
      if (color) {
        return { background: JSON.parse(color).hex };
      } else {
        return { background: "#3a68ec" };
      }
    } catch (error) {
      return { background: "#3a68ec" };
    }
  }
  return (
    <React.Fragment>
      {loadingPage ? (
        <Loader loading={loadingPage}></Loader>
      ) : (
        <DashboardContainer>
          {dataPage.EVENT && dataPage.EVENT.EVENT_ACTIVE === "true" && isEvent && (
            <div
              className="event"
              style={renderColor(dataPage.EVENT.EVENT_COLOR || "")}
            >
              <div className="e-content">
                <img
                  src={closeE}
                  onClick={() => {
                    setIsEvent(false);
                  }}
                  alt=""
                  className="close"
                />
                <div className="top">
                  <img
                    src={dataPage.EVENT.EVENT_IMG || ""}
                    alt=""
                    className="e"
                  />
                  <div className="e-header">
                    <div className="title">
                      {dataPage.EVENT.EVENT_HEADER || ""}
                    </div>
                    <div className="subTitle">
                      {dataPage.EVENT.EVENT_TITLE || ""}
                    </div>
                  </div>
                </div>
                <div
                  className="e-description"
                  dangerouslySetInnerHTML={{
                    __html: dataPage.EVENT.EVENT_DETAIL || "",
                  }}
                ></div>

                <a
                  className="click-now"
                  href={
                    dataPage.EVENT.EVENT_LINK
                      ? "http://" + renderWebsite(dataPage.EVENT.EVENT_LINK)
                      : ""
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Now
                </a>
              </div>
            </div>
          )}
          <WP>
            <BackTop>
              <div className="ant-back-top-inner">
                <span className="scroll-up">
                  <span className="left-bar"></span>
                  <span className="right-bar"></span>
                  <svg width="40" height="40">
                    <line className="top" x1="0" y1="0" x2="120" y2="0" />
                    <line className="left" x1="0" y1="40" x2="0" y2="-80" />
                    <line className="bottom" x1="40" y1="40" x2="-80" y2="40" />
                    <line className="right" x1="40" y1="0" x2="40" y2="1200" />
                  </svg>
                </span>
              </div>
            </BackTop>
          </WP>
          <DashboardGlobalStyles />
          <Router>
            <Header />
            <Nav />
            {/* <Slide /> */}
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={({ match }) => <route.component match={match} />}
                />
              ))}
            </Switch>
            <Footer></Footer>
          </Router>
        </DashboardContainer>
      )}
    </React.Fragment>
  );
}
const WP = styled.div`
  .ant-back-top {
    bottom: 130px;
    right: 2%;
  }

  .scroll-up {
    height: 40px;
    width: 40px;
    display: block;
    outline: 1px solid rgba(666, 666, 666, 0.08);
    position: relative;
    cursor: pointer;
    svg line {
      stroke-width: 1;
      stroke: #aaa;
      fill: none;
      stroke-dasharray: 20;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    &:hover svg line {
      &.top {
        -webkit-transform: translateX(-40px);
        transform: translateX(-40px);
      }
      &.bottom {
        -webkit-transform: translateX(40px);
        transform: translateX(40px);
      }
      &.left {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
      }
      &.right {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
      }
    }
  }

  .scroll-up {
    .left-bar {
      position: absolute;
      z-index: 1;
      background-color: transparent;
      top: 19px;
      left: 5px;
      width: 18px;
      display: block;
      transform: rotate(-45deg);
      &:after {
        content: "";
        background-color: #666;
        width: 18px;
        height: 1px;
        display: block;
        border-radius: 1px;
        transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
        transform-origin: right center;
        z-index: -1;
      }
    }
    .right-bar {
      position: absolute;
      z-index: 1;
      background-color: transparent;
      top: 19px;
      left: 17px;
      width: 18px;
      display: block;
      transform: rotate(45deg);
      border-radius: 2px;
      &:after {
        content: "";
        background-color: #666;
        width: 18px;
        height: 1px;
        display: block;
        border-radius: 1px;
        transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
        transform-origin: left center;
        z-index: -1;
      }
    }
    &:hover {
      transition: all 0.1s;
      .left-bar:after {
        transform: rotate(-10deg);
      }
      .right-bar:after {
        transform: rotate(10deg);
      }
    }
  }
`;
