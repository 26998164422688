import React from "react";
import styled from "styled-components";
import mail_ic from "@iso/assets/images/ic/mail.svg";
import phone_ic from "@iso/assets/images/ic/phone_call.svg";
import mini_arr from "@iso/assets/images/ic/mini_arr.svg";
import wall_clock from "@iso/assets/images/ic/wall-clock.svg";
import cpy from "@iso/assets/images/ic/cpy.svg";
import { formatStringToDate } from "@iso/lib/helpers/function.util";
import imgDefault from "@iso/assets/images/imgDefault.png";
import * as DIV from "../../UIKIT/Div";
import Frame5 from "@iso/assets/images/Frame5.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { renderWebsite } from "@iso/utils/function.util";
import { device } from "@iso/UIKIT/device.js";
import { get } from "lodash";
export default function Footer() {
  const { CONTACT, SIDEBAR, FOOTER } = useSelector(
    (state) => state.commonReducer.dataPage
  );

  const dataTags = useSelector((state) => state.commonReducer.dataTags);

  const { recentPost } = useSelector((state) => state.articleReducer);

  return (
    <FOOTERWP>
      {CONTACT ? (
        <FOOTERCONTACT>
          <RowCT>
            <div>
              <img src={wall_clock} alt="Hotline Golden" />
            </div>
            <COLCT>
              <TXTHOTLINE>OPEN(MON-SAT)</TXTHOTLINE>
              <TITLEHOTLINESUB>
                {CONTACT.CONTACT_BUSSINESSHOURS || ""}
              </TITLEHOTLINESUB>
            </COLCT>
          </RowCT>
          <RowCT>
            <div>
              <img src={phone_ic} alt="Hotline Golden" />
            </div>
            <COLCT>
              <TXTHOTLINE>HOTLINE</TXTHOTLINE>
              <TITLEHOTLINESUB>{CONTACT.CONTACT_HOTLINE || ""}</TITLEHOTLINESUB>
            </COLCT>
          </RowCT>
          <RowCT>
            <div>
              <img src={mail_ic} alt="Hotline Golden" />
            </div>
            <COLCT>
              <TXTHOTLINE>EMAIL</TXTHOTLINE>
              <TITLEHOTLINESUB>{CONTACT.CONTACT_EMAIL || ""}</TITLEHOTLINESUB>
            </COLCT>
          </RowCT>
        </FOOTERCONTACT>
      ) : null}
      <MAIN bg={Frame5}>
        <RECENTPOSTWP>
          {FOOTER && (
            <SESSION>
              <h1>{FOOTER.FOOTER_HEADER || "ABOUT US"}</h1>
              <Line></Line>
              <p style={{ marginBottom: "16px" }}>
                {FOOTER.FOOTER_SHORTDETAIL || ""}
              </p>
              <IMGLOGO src={FOOTER.FOOTER_IMG || imgDefault} alt=""></IMGLOGO>
              {/* <BOXCOLOR></BOXCOLOR> */}
            </SESSION>
          )}
          <SESSION className="recentPost">
            <h1>RECENT POST</h1>
            <Line></Line>
            {recentPost &&
              recentPost.length > 0 &&
              recentPost.map((props, index) => {
                if (index > 1) return true;
                return (
                  <LISTITEM key={index}>
                    <IMGX src={props.thumbnail || imgDefault}></IMGX>

                    <ITEM>
                      <Link to={"/blog/" + props.id || ""} alt="">
                        {props.title}
                      </Link>
                      <p>
                        {props.createdDate
                          ? formatStringToDate(props.createdDate)
                          : ""}
                      </p>
                    </ITEM>
                  </LISTITEM>
                );
              })}
          </SESSION>
          <SESSION className="tags">
            <h1>TAGS</h1>
            <Line></Line>
            {dataTags && (
              <TAGWP>
                {dataTags &&
                  dataTags.length > 0 &&
                  dataTags.map((prop, index) => {
                    return (
                      <TAGITEM
                        href={"/blog?page=1&tags=" + prop.name || ""}
                        key={index}
                      >
                        {prop.name || ""}
                      </TAGITEM>
                    );
                  })}
              </TAGWP>
            )}
          </SESSION>
          {SIDEBAR && (
            <SESSION className="ourservice">
              <h1>{SIDEBAR.SIDEBAR_HEADER || "OUR SERVICE"}</h1>
              <Line></Line>
              <p style={{ marginBottom: "16px" }}>
                {SIDEBAR.SIDEBAR_SHORTDETAIL || ""}
              </p>
              {SIDEBAR.SIDEBAR_TITLE1 && (
                <ROW>
                  <IMG src={mini_arr} alt="" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SIDEBAR.SIDEBAR_LINK1 || "#bob"}
                    alt=""
                  >
                    {SIDEBAR.SIDEBAR_TITLE1 || ""}
                  </a>
                </ROW>
              )}
              {SIDEBAR.SIDEBAR_TITLE2 && (
                <ROW>
                  <IMG src={mini_arr} alt="" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SIDEBAR.SIDEBAR_LINK2 || "#bob"}
                    alt=""
                  >
                    {SIDEBAR.SIDEBAR_TITLE2 || ""}
                  </a>
                </ROW>
              )}
              {SIDEBAR.SIDEBAR_TITLE3 && (
                <ROW>
                  <IMG src={mini_arr} alt="" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SIDEBAR.SIDEBAR_LINK3 || "#bob"}
                    alt=""
                  >
                    {SIDEBAR.SIDEBAR_TITLE3 || ""}
                  </a>
                </ROW>
              )}
            </SESSION>
          )}
        </RECENTPOSTWP>
      </MAIN>
      {CONTACT ? (
        <CoppyRight>
          <CoppyRightContent>
            <TXT>
              <img src={cpy} alt=""></img> {FOOTER.FOOTER_COPYRIGHT || ""} -
              Powered by{" "}
              <a
                rel="noopener noreferrer"
                href="https://www.aegona.com/"
                target="_blank"
              >
                Aegona
              </a>{" "}
              Ltd,
            </TXT>
            <TXTIMG>
              {CONTACT.CONTACT_LINKEDIN_LINK && (
                <a
                  href={
                    "http://" + renderWebsite(CONTACT.CONTACT_LINKEDIN_LINK)
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_LINKEDIN_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_YOUTUBE_LINK && (
                <a
                  href={"http://" + renderWebsite(CONTACT.CONTACT_YOUTUBE_LINK)}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_YOUTUBE_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_TWITTER_LINK && (
                <a
                  href={"http://" + renderWebsite(CONTACT.CONTACT_TWITTER_LINK)}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_TWITTER_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
              {CONTACT.CONTACT_FACEBOOK_LINK && (
                <a
                  href={
                    "http://" + renderWebsite(CONTACT.CONTACT_FACEBOOK_LINK)
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  alt=""
                >
                  <img
                    src={get(CONTACT, "CONTACT_FACEBOOK_IMG", "")}
                    alt=""
                  ></img>
                </a>
              )}
            </TXTIMG>
          </CoppyRightContent>
        </CoppyRight>
      ) : null}
    </FOOTERWP>
  );
}
const MAIN = styled.div`
  width: 100%;
  margin-top: 35px;
  background: rgba(10, 48, 78, 0.92);
  padding: 58px 0px 58px 0px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background: #0a2c47; */
  @media ${device.tablet} {
    padding: 20px 0px 20px 0px;
  }
`;
const FOOTERWP = styled.div`
  background: #0a2c47;
  width: 100%;
  margin-top: 60px;
  padding: 35px 0px 0px 0px;
  @media ${device.tablet} {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }
`;
const CoppyRight = styled.div`
  background: #0a2c47;
`;
const CoppyRightContent = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 0px;
  @media ${device.tablet} {
    width: 90%;
    flex-direction: column;
  }
  a {
    color: rgba(255, 253, 242, 0.82);
    &:hover {
      color: rgba(255, 253, 242, 0.82);
    }
  }
`;
const TXT = styled.span`
  color: rgba(255, 253, 242, 0.82);
  font-weight: normal;
  font-size: 14px;
  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 5px;
  }
`;
const TXTIMG = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 16px;
  }
`;
const FOOTERCONTACT = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
    width: 90%;
    justify-content: initial;
  }
`;
const RowCT = styled(DIV.Row)`
  width: 33%;
  justify-content: center;
  @media ${device.tablet} {
    width: 100%;
    justify-content: initial;
  }
  img {
    margin-right: 12px;
    padding-top: 10px;
  }
  &:last-child {
    margin-right: 0px;
  }
`;
const COLCT = styled(DIV.Col)``;
const TXTHOTLINE = styled(DIV.TextSM)`
  font-weight: normal;
  margin-bottom: 5px;
  @media ${device.tablet} {
    margin-bottom: 2px;
  }
`;
const TITLEHOTLINESUB = styled(DIV.TextSM)`
  font-weight: bold;
  font-size: 18px;
  color: rgba(255, 245, 241, 0.82);
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const RECENTPOSTWP = styled.div`
  width: 85%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .recentPost {
    margin: 20px 0px;
  }
  .ourservice {
    margin-top: 20px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    color: #fffdf2;
  }
  h1 {
    font-weight: bold;
    font-size: 20px;
    color: #fffdf2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media ${device.tablet} {
    flex-direction: column;
    width: 90%;
  }
`;
const ROW = styled.div`
  display: flex;
  a {
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 253, 242, 0.82);
  }
`;
// const SOCIALWP = styled.div`
//   display: flex;
// `;
// const IMGSC = styled.img`
//   margin-right: 12px;
// `;
const TAGWP = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TAGITEM = styled.a`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 6px 16px;
  color: #fffdf2;
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
`;
const SESSION = styled.div`
  width: 24%;
  @media ${device.tablet} {
    width: 100%;
  }
`;
const LISTITEM = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const IMG = styled.img`
  margin-right: 9px;
`;
const IMGLOGO = styled.img`
  max-width: 100%;
  height: auto;
`;
const IMGX = styled.img`
  width: 73.29px !important;
  height: 72px;
  display: block;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 5px;
`;
const ITEM = styled.div`
  flex: 1;
  a {
    font-weight: bold;
    font-size: 16px;
    color: #fffdf2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 253, 242, 0.37);
  }
`;
const Line = styled.div`
  width: 100%;
  border-radius: 0px;
  height: 2px;
  margin: 14px 0px 24px 0px;
  width: 80px;
  background: #f4c70b;
`;
