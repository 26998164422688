import { lazy } from "react";
import { PUBLIC_ROUTE } from "./route.constants";
export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    component: lazy(() => import("@iso/containers/Home/Home")),
    exact: true
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500"))
  },
  {
    path: "/health",
    component: lazy(() => import("@iso/containers/Pages/Health"))
  },
  {
    path: "/service",
    component: lazy(() => import("@iso/containers/Service/Service")),
    exact: true
  },
  {
    path: "/blog",
    component: lazy(() => import("@iso/containers/Blog/Blog")),
    exact: true
  },
  {
    path: "/blog/:id",
    component: lazy(() => import("@iso/containers/Blog/Detail")),
    exact: true
  },
  {
    path: "/contact",
    component: lazy(() => import("@iso/containers/Contact/Contact")),
    exact: true
  },
  {
    path: "/about",
    component: lazy(() => import("@iso/containers/AboutUs/AboutUs")),
    exact: true
  },
  {
    path: "/recruitment",
    component: lazy(() => import("@iso/containers/Recruitment/Recruitment")),
    exact: true
  },
  {
    path: "/recruitment/:id",
    component: lazy(() => import("@iso/containers/Recruitment/Detail")),
    exact: true
  },
  {
    component: lazy(() => import("@iso/containers/Pages/404/404"))
  }
];
